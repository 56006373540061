import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectName from "../components/ProjectName"
import Video from "../components/Video"
import { Routing } from "../utils/routing"

const title = Routing.HOME.title

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <SEO title={title} />
    <h1>{title}</h1>
    <Video />

    {/*<div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>*/}
    {/*  <Image />*/}
    {/*</div>*/}
    <p>
      Welcome to <ProjectName/>, your unique way to wager on fantasy
      sports.{' '}
    </p>
    <p> Let’s take a look on how to play</p>
    <p> First, choose your sport and than your game</p>
    <p>
      {' '}
      Next - view the lineups and determine if the favorite will win
      by more than the expected fantasy points.
    </p>
    <p> You can view our scoring metrics in the rules section.</p>
    <p> Each team’s expectations of fantasy points may differ.</p>
    <p>
      The difference between the expected fantasy points will become
      the spread meaning the underdog will add the difference onto
      his score at the end of the game.
    </p>
    <p> Whoever has the most points at the end of the game -wins</p>
    <p>
      {' '}
      Any game you choose to play, you will use <ProjectName/>’s trading
      platform.
    </p>
    <p>
      {' '}
      Go to our unique trading platform and choose the side that you
      think will win.
    </p>
    <p>
      {' '}
      Example: Dallas is to win by more than four fantasy points
    </p>
    <p> Now it's time to either buy or sell units</p>
    <p>
      {' '}
      Keep In mind that others may have a different opinion and may
      take the other side of your trade.
    </p>
    <p>
      {' '}
      In this case the buyer and seller will automatically match up
      and the trade will be completed.
    </p>
    <p>
      Dallas wins ninety six - ninety one. Meaning everyone owning
      Dallas units at the end of the game are now worth one dollar
    </p>
    <p>
      {' '}
      So if you purchased fifty units at fifty cents which equals
      twenty five dollars your fifty units will now be worth fifty
      dollars, which will be simply credited back to your account.
    </p>
    <p>
      But here is what differs <ProjectName/> from any other fantasy sports
      site You don’t have to wait for the end of the game. Anytime
      during the game you can sell your units, or even buy more.
    </p>
    <p>
      Just use <ProjectName/>’s point and click trading platform to enter
      your trades.
    </p>
    <p>Check out other games available on the platform</p>
    <p>Enjoy the <ProjectName/> experience and start winning! </p>
  </Layout>
)

export default IndexPage
