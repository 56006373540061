import React from "react"
import { getProjectMetadata } from "../../utils/projectMetadataUtil"
import { env } from "../utils/evnVariablesHandlers"
import styled from "styled-components"

const videoSourceURL = getProjectMetadata(env.__PROJECT__).urls.video
const withSettingsUrl = `${videoSourceURL}?muted=false&autoplay=true&playsinline&false&portrait=false&byline=false`

const Wrapper = styled.div`
  height: 400px;
  width: auto;
  position: relative;
  margin-bottom: 30px;
  iframe {
    height: 100%;
    width: 100%;
  }
`

const Video = ({ ...props }) => (
  <Wrapper className="video">
    <iframe
      src={withSettingsUrl}
      title="How It Works"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </Wrapper>
)

export default Video
